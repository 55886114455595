import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'prefix',
    loadChildren: () =>
      import('./modules/login/container/login.module').then(
        (m) => m.LoginModule
      ),
  },
  {
    path: '',
    pathMatch: 'prefix',
    loadChildren: () =>
      import('./modules/layout/container/layout.module').then(
        (m) => m.LayoutModule
      ),
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
