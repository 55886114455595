<div
  #snackbarContainer
  class="snack-bar"
  fxLayout="row"
  fxLayoutAlign="space-between center"
>
  <div
    *ngIf="
      data.type === SnackbarDisplayType.labelOnly ||
      data.type === SnackbarDisplayType.labelWithButton ||
      data.type === SnackbarDisplayType.labelWithTwoButtons
    "
    style="display: flex;"
    class="text-field"
  >
    <span class="label Or14-c-white">{{ data.message }}</span>
  </div>
  <div
    *ngIf="data.type === SnackbarDisplayType.inputWithButton"
    class="snackbar-input"
  >
    <mat-form-field appearance="outline">
      <mat-label>{{ data.message }}</mat-label>
      <input
        #saveText
        matInput
        placeholder="{{ data.message }}"
        (input)="OnValueChange($event.target.value)"
      />
    </mat-form-field>
  </div>
  <div *ngIf="data.type === SnackbarDisplayType.scrollableText">
    <div class="snackbarHeader">
      <mat-icon class="errorOutline" svgIcon="errorOutline"></mat-icon>
      <mat-label class="textAlignment Or14-c-white"
        >No Results Found :</mat-label
      >
      <mat-icon
        class="close-icon-missingData Or14-c-white"
        (click)="CloseAction()"
      >
        close
      </mat-icon>
    </div>
    <div
      [ngStyle]="{
        'overflow-y':
          data.message && data.message.length >= 5 ? 'scroll' : 'hidden'
      }"
      style="max-height: 120px; width: 413px;"
    >
      <div
        class="snackbarBody"
        style="margin-top: 4px !important;"
        *ngFor="let missingRecord of data.message"
      >
        <span class="Or14-c-white" style="font-size: 12px;">{{
          missingRecord
        }}</span>
      </div>
    </div>
  </div>

  <button
    *ngIf="
      data.type === SnackbarDisplayType.inputWithButton ||
      data.type === SnackbarDisplayType.labelWithButton ||
      data.type === SnackbarDisplayType.labelWithTwoButtons
    "
    class="button Osb14-c-white-lh-21"
    [ngClass]="buttonClass(data.ButtonClass)"
    mat-icon-button
    [disabled]="this.CanDisable()"
    (click)="ClickAction()"
  >
    {{ data.ButtonLabel }}
  </button>
  <button
    *ngIf="data.type === SnackbarDisplayType.labelWithTwoButtons"
    class="button Osb14-c-white-lh-21"
    [ngClass]="buttonClass(data.SecondButtonClass)"
    mat-icon-button
    [disabled]="this.CanDisable()"
    (click)="secondButtonClick()"
  >
    {{ data.SecondButtonLabel }}
  </button>

  <!-- confirmation snack bar -->
  <div
    *ngIf="data.type === SnackbarDisplayType.inputWithButtonAndConfirmation"
    class="confirmation-outer"
  >
    <div class="input-container" [hidden]="showConfirmation">
      <mat-form-field appearance="outline">
        <mat-label>{{ data.message }}</mat-label>
        <input
          #saveText
          matInput
          value="{{ data.confirmationData?.defaultName }}"
          placeholder="{{ data.message }}"
          (input)="OnValueChange($event.target.value)"
        />
      </mat-form-field>

      <button
        class="button save-preset-confirm Osb14-c-white-lh-21"
        mat-icon-button
        [disabled]="this.CanDisable()"
        (click)="CheckForExisting()"
      >
        {{ data.ButtonLabel }}
      </button>
    </div>
    <div class="show-confirmation-container" [hidden]="!showConfirmation">
      <label class="confirmation-label">{{
        data.confirmationData?.label
      }}</label>

      <button
        class="confirmation-btn confirm-btn"
        (click)="ConfirmChange()"
        mat-icon-button
      >
        {{ data.confirmationData?.confirm }}
      </button>
      <button
        class="confirmation-btn cancel-btn"
        (click)="ToggleConfirmation(true)"
        mat-icon-button
      >
        {{ data.confirmationData?.cancel }}
      </button>
    </div>
  </div>

  <div
    class="close-snack-bar"
    *ngIf="data.type !== SnackbarDisplayType.scrollableText"
  >
    <mat-icon
      class="close-icon"
      style="padding-right: 0px;"
      (click)="CloseAction()"
    >
      close
    </mat-icon>
  </div>
</div>
