import { Subscription } from 'rxjs';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Inject,
  ViewEncapsulation,
  OnDestroy,
} from '@angular/core';
import {
  MAT_SNACK_BAR_DATA,
  MatSnackBarRef,
} from '@angular/material/snack-bar';
import { ISnackBarConfig, SnackbarType } from './snack-bar-config';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-snack-bar',
  templateUrl: './snack-bar.component.html',
  styleUrls: ['./snack-bar.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class SnackBarComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  userInput!: string;
  action = '';
  SnackbarDisplayType = SnackbarType;
  showConfirmation = false;
  savedLabels!: any[];

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    public snackBarRef: MatSnackBarRef<SnackBarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: ISnackBarConfig
  ) {
    this.matIconRegistry.addSvgIcon(
      'errorOutline',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/ErrorOutline3x.svg'
      )
    );
    if (this.data?.confirmationData?.data) {
      this.savedLabels = this.data.confirmationData?.data;
      this.userInput = this.data.confirmationData.defaultName || '';
    }

    this.subscription = snackBarRef.onAction().subscribe(() => {
      if (this.action.includes('action') && data.OnButtonClicked) {
        data.OnButtonClicked(this.userInput);
      } else if (
        this.action.includes('secondButtonClick') &&
        data.OnSecondButtonClicked
      ) {
        data.OnSecondButtonClicked(this.userInput);
      } else if (data.OnDismissClicked) {
        data.OnDismissClicked();
      }
    });
  }

  ngOnInit(): void {
    if (this.data.type === this.SnackbarDisplayType.labelOnly) {
      // setTimeout(() => {
      //   this.snackBarRef.dismiss();
      // }, 3000);
    }
  }

  ClickAction(): void {
    this.action = 'action';
    this.snackBarRef.dismissWithAction();
  }
  secondButtonClick() {
    this.action = 'secondButtonClick';
    this.snackBarRef.dismissWithAction();
  }
  CloseAction(): void {
    this.action = 'dismiss';
    this.snackBarRef.dismiss();
    if (this.data.OnDismissClicked) {
      this.data.OnDismissClicked();
    }
  }

  OnValueChange(data: string) {
    this.userInput = data;
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
  CanDisable(): boolean {
    if (
      this.data.type === SnackbarType.inputWithButton ||
      this.data.type === SnackbarType.inputWithButtonAndConfirmation
    ) {
      if (this.userInput) {
        return this.userInput.trim().length === 0;
      }
      return true;
    } else {
      return false;
    }
  }

  ToggleConfirmation(hideConfirmation?: boolean) {
    if (hideConfirmation === true) {
      this.showConfirmation = false;
      return;
    }
    this.showConfirmation = true;
  }

  ConfirmChange(): void {
    this.action = 'action';
    this.snackBarRef.dismissWithAction();
  }

  CheckForExisting() {
    const exists = this.savedLabels.find(
      (keyLabel) => keyLabel.label === this.userInput
    );

    if (exists) {
      this.ToggleConfirmation();
    } else {
      this.ConfirmChange();
    }
  }
  buttonClass(className: string | undefined) {
    return className ? [className] : [];
  }
}
