import { Injectable } from '@angular/core';
import { BaseHttpService } from '../../../core/services/base-http.service';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from './../../../app-config';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root',
})
export class LoginService extends BaseHttpService {
  constructor(http: HttpClient, public router: Router) {
    super(http);
  }
  login(payload: any): Observable<any> {
    return this.postData<any>(AppConfig.userLogin, payload);
  }

  logout() {
    window.sessionStorage.clear();
    this.router.navigate(['/login']);
  }
}
