import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LoginDataService {
  sharedData: any = {};

  constructor() { }
  setSharedData(key: string, data: any, setInStorage = false) {
    if (setInStorage) {
      window.sessionStorage.setItem(key, JSON.stringify(data));
    }

    this.sharedData[key] = data;
  }

  getSharedData(key: string): any {
    if (window.sessionStorage.getItem(key)){
      const userDetails = window.sessionStorage.getItem(key);
      if (userDetails) {
        return JSON.parse(userDetails);
      }
    }
    return this.sharedData[key];
  }

  deleteSharedData(key: string): any {
    window.sessionStorage.deleteItem(key);
    delete this.sharedData[key];
  }
}
