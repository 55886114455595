import { ISnackBarConfig, SnackbarType } from './snack-bar-config';
import { SnackBarComponent } from './snack-bar.component';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SnackBarService {
  ref!: MatSnackBarRef<SnackBarComponent>;

  constructor(private matSnackBar: MatSnackBar) {}

  Open(config: ISnackBarConfig): void {
    let snackBarPanelClass = ['snack-bar-max-width'];
    if (
      config.type === SnackbarType.buttonOnly ||
      config.type === SnackbarType.labelOnly
    ) {
      snackBarPanelClass = ['snack-bar-min-witdh'];
    } else if (config.type === SnackbarType.inputWithButtonAndConfirmation) {
      snackBarPanelClass = ['snack-bar-confirmation-max-width'];
    } else if (config.type === SnackbarType.scrollableText) {
      snackBarPanelClass = ['snackbar-missing-records-width-height'];
    }

    this.matSnackBar.openFromComponent(SnackBarComponent, {
      data: { ...config },
      panelClass: snackBarPanelClass,
    });
  }
  close(): void {
    this.matSnackBar.dismiss();
  }
}
