import { LoginService } from './../../modules/login/services/login.service';
import { SnackBarService } from '../../core/components/snack-bar/snack-bar.service';
import {
  ISnackBarConfig,
  SnackbarType,
} from '../../core/components/snack-bar/snack-bar-config';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private snackBar: SnackBarService,
    private loginService: LoginService
  ) {}

  message: any;
  error: any;
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if ([401, 403].indexOf(err.status) !== -1) {
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          this.loginService.logout();
          location.reload(true);
        }

        this.error = err.error.message || err.statusText;
        if (this.error == 'Bad credentials') {
          this.error = 'Invalid Email Id or Password';
        }
        if (this.error == 'OK') {
          this.error = 'Rate request has been submitted, an email confirmation will be sent.';
        }
        const config: ISnackBarConfig = {
          message: this.error,
          type: SnackbarType.labelOnly,
        };

        this.snackBar.Open(config);
        return throwError(this.error);
      })
    );
  }
}

// return next.handle(request).pipe(
//     tap(
//       event => { },
//       error => {
//         if (error instanceof HttpErrorResponse) {
//           this.toasterService.error(error.message, error.name, { closeButton: true });
//         }
//       }
//     ));
