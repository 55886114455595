import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../src/environments/environment';
import { AsyncSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export class BaseHttpService {
  constructor(private http: HttpClient) {}

  getAssetsData<T>(url: string): Observable<T> {
    return this.http.get<T>('./assets/' + `${url}`);
  }

  private getJsonData<T>(url: string): Observable<T> {
    const subject = new AsyncSubject<T>();
    this.http
      .get<T>(`${environment.apiUrl}/${url}` + '.json')
      .subscribe(subject);
    return subject.asObservable();
  }

  getData<T>(url: string): Observable<T> {
    if (environment.localJson) {
      return this.getJsonData<T>(url);
    }

    const subject = new AsyncSubject<T>();

    this.http
      .get(`${environment.apiUrl}/${url}`)
      .pipe(
        map((response) => this.parseData<T>(response)),
        catchError((error) => {
          return throwError(error);
        })
      )
      .subscribe(subject);
    return subject.asObservable();
  }

  postData<T>(url: string, jsonContent: any, options?: any): Observable<any> {
    if (environment.localJson) {
      return this.getJsonData<T>(url);
    }
    //const subject = new AsyncSubject<T>();

    return this.http
      .post(`${environment.apiUrl}/${url}`, jsonContent, options)
      .pipe(
        map((response) => this.parseData<T>(response)),
        catchError((error) => {
          return throwError(error);
        })
      )
    
    
  }

  putData<T>(url: string, jsonContent: any): Observable<any> {
    if (environment.localJson) {
      return this.getJsonData<T>(url);
    }

    const subject = new AsyncSubject<T>();

    const body = JSON.stringify(jsonContent);
    this.http
      .put(`${environment.apiUrl}/${url}`, body)
      .pipe(
        map((response) => this.parseData<T>(response)),
        catchError((error) => {
          return throwError(error);
        })
      )
      .subscribe(subject);
    return subject.asObservable();
  }

  deleteData<T>(url: string): Observable<any> {
    const subject = new AsyncSubject<T>();

    this.http
      .delete(`${environment.apiUrl}/${url}`)
      .pipe(
        map((response) => this.parseData<T>(response)),
        catchError((error) => {
          return throwError(error);
        })
      )
      .subscribe(subject);
    return subject.asObservable();
  }

  // directUrl(url: string, payload: any) {
  //     this.http
  //         .post(url, payload)
  //         .pipe(
  //             catchError((error) => {
  //                 return throwError(error);
  //             })
  //         )
  //         .subscribe((subject) => console.log(subject));
  // }

  parseData<T>(responseData: any): T {
    const body = responseData;
    return body || {};
  }
}
