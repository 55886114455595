// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
 //apiUrl: 'http://localhost:8000',
  //apiUrl: 'http://158.101.126.104:8000',
    //apiUrl: 'http://10.51.0.18:8000',
  //  apiUrl:'http://10.51.1.9:8000',
    //apiUrl: 'http://150.136.27.243:8000',
    //apiUrl:'http://129.213.148.99:8000',
    //apiUrl:'https://ogc-stg.apllogistics.com',
    apiUrl:'https://oceanguaranteed.apllogistics.com',
  localJson: false,
};
/*
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
