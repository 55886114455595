export class AppConfig {
  public static fclOrigin = 'OGC/ogFCLOrigins';
  public static fclServices = 'OGC/ogFCLServiceTypes';
  public static fclContainers = 'OGC/ogFCLContainerTypes';
  public static fclResult = 'OGC/ogFclResult';
  public static fclCommodity = 'OGC/ogFCLCommodities';
  public static lclOrigin = 'OGC/ogLclOrigins';
  public static lclCountries = 'OGC/ogLclDestCountries';
  public static lclResult = 'OGC/ogLclCharges';
  public static guranteedDateSchedule='OGC/getArrivelDataInfo';
  public static userLogin = 'OGC/v1/login';
  public static sendLclData = 'OGC/crmIntegrationForm';
  public static sendFclData = 'OGC/crmIntegrationFormFCL';
  public static signupData ='OGC/v1/createUser';
  public static forgotPasswordData= 'OGC/v1/forgetPassword'
}
