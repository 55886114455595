import { HttpClientModule } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { CommonImportModule } from '../../app/core/import/common-import.module';
import { EnsureModuleLoadedOnceGuard } from './guards/ensureModuleLoadedOnceGuard';
import { httpInterceptorProviders } from './interceptors/index';

@NgModule({
  declarations: [],
  imports: [CommonImportModule, HttpClientModule, MatSnackBarModule],
  exports: [HttpClientModule],
  providers: [httpInterceptorProviders],
})
export class CoreModule extends EnsureModuleLoadedOnceGuard {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    super(parentModule);
  }
}
