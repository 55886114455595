import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
    declarations: [],
    imports: [CommonModule, FormsModule, FlexLayoutModule],
    exports: [CommonModule, FormsModule, FlexLayoutModule],
})
export class CommonImportModule {}
