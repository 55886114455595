import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from './modules/login/services/login.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'OGC';
}
