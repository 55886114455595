export interface ISnackBarConfig {
  message: string | undefined | string[];
  type: SnackbarType;
  ButtonLabel?: string;
  SecondButtonLabel?: string;
  confirmationData?: IConfirmationModal;
  ButtonClass?: string;
  SecondButtonClass?: string;
  OnButtonClicked?: (...args: any) => void;
  OnSecondButtonClicked?: (...args: any) => void;
  OnDismissClicked?: () => void;
}

export interface IConfirmationModal {
  label?: string;
  confirm?: string;
  cancel?: string;
  defaultName?: string;
  data?: any[];
}

export enum SnackbarType {
  labelOnly,
  buttonOnly,
  labelWithButton,
  inputWithButton,
  inputWithButtonAndConfirmation,
  labelWithTwoButtons,
  scrollableText,
}
